import "./App.css";
import MyPdfViewer from "./components/MyPdfViewer";
import Lookbook from "./components/Lookbook";
import { Routes, Route } from 'react-router-dom';

export default function App() {
    return (
        <Routes>
            <Route path="/" element={<MyPdfViewer />} />
            <Route path="/lookbook" element={<Lookbook />} />
        </Routes>
    );
}