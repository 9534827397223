import React, {useEffect} from "react";

function MyPdfViewer() {
    const pdfLink = "portfolio.pdf";

    // Function to detect mobile device, defined outside useEffect
    const isMobileDevice = () => {
        return (
            typeof window.orientation !== "undefined" ||
            navigator.userAgent.indexOf("IEMobile") !== -1
        );
    };

    useEffect(() => {
        // Automatically download PDF on mobile devices
        if (isMobileDevice()) {
            const link = document.createElement("a");
            link.href = pdfLink;
            link.download = pdfLink;
            document.body.appendChild(link); // Append to body
            link.click();
            document.body.removeChild(link); // Clean up
        }
    }, []);

    // Render iframe or nothing based on device type
    return (
        <div className="pdf-page" id="pdf-page">
            {!isMobileDevice() && (
                <iframe
                    name="ROBLEK_Tana_PORTFOLIO"
                    title="ROBLEK_Tana_PORTFOLIO"
                    src={pdfLink}
                    allowFullScreen
                ></iframe>
            )}
        </div>
    );
}

export default MyPdfViewer;
