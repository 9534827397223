import {useEffect, useState} from 'react';
import './Lookbook.css';
import {MasonryPhotoAlbum} from "react-photo-album";
import "react-photo-album/rows.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {useNavigate} from 'react-router-dom';

export default function Lookbook() {
    const [images, setImages] = useState([]);
    const [index, setIndex] = useState(-1);
    const navigate = useNavigate(); // ✅ React Router hook


    useEffect(() => {
        fetch('/images.json')
            .then((res) => res.json())
            .then((data) => {
                const mappedImages = data.map((img) => ({
                    src: `/images/${img.filename}`,
                    width: img.width,
                    height: img.height,
                }));
                setImages(mappedImages);
            });
    }, []);

    return (
        <div className="lookbook-container">
            <button className="back-button" onClick={() => navigate('/')}>←</button>

            <div className="lookbook-title">
                <h1>LOOKBOOK</h1>
            </div>
            <div className="lookbook-tag">MENSWEAR - LIA</div>
            <div className="photo-album">
                <MasonryPhotoAlbum photos={images} columns={(containerWidth) => {
                    if (containerWidth < 400) return 2;
                    return 3;
                }} spacing={0} padding={(containerWidth) => {
                    if (containerWidth < 800) return 5;
                    return 10;
                }} onClick={({index}) => setIndex(index)}
                />
            </div>
            <Lightbox
                slides={images}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </div>
    );
}
